<template>
  <!--clan create modal window -->
 <CreateClan @CreateClanCancel="CreateClanCancel" @CreateClanSuccess="CreateClanSuccess" v-if="create_clan_page_visible"></CreateClan>

  <div class="wood_cont create_clan">
    <img class="wood" src="../assets/Upgrade_assets/upgrade.png"/>
  </div>

  <div class="clans">
    <!--clan create modal window - create -->
    <div class="make_clan">
      <img src="../assets/clans/test.svg" class="make-clan-frame"/>
      <div class="make_clans_box">
        <form @submit.prevent="GetClansSearch">
          <input type="text" v-model="searchNameClan" placeholder="ENTER CLAN NAME" /> <br/>
        </form>
        <div class="make-clan-small-box">
          <div class="mini_button" @click="GetClansSearch">
            <img src="../assets/clans/search.svg"/>
          </div>
          <div class="mini_button" @click="CreateClan()">
            <img src="../assets/clans/plus.svg"/>
          </div>
        </div>
      </div>
    </div>

    <!--clans list-->
    <div class="_frame white scroll-add">
      <div class="block" v-for="clan in clans_list" v-bind:key="clan" v-on:click="VisitClanPage(clan.id)">
        <img src="../assets/MoneyImages/clan_img.png"/>

        <div class="block_text">
          <span class = "upper">{{ clan.name }}</span>
          <span>{{ clan.silver }} silver</span>
          <span>{{ clan.number_of_members }} members</span>
        </div>
        <div class = "block_lead">
          <span>{{ clan.owner_username }}</span>
        </div>
      </div>

      
    </div>
  </div>
</template>

<style>
input::placeholder{
  color: rgb(255 255 255);
}

</style>

<script>
import axios from "axios";
import CreateClan from "@/components/ClansView__CreateClan.vue";

export default {
  components: {
    CreateClan,
  },
  data() {
    return {
      create_clan_page_visible: false,
      refreshed: true,
      searchNameClan: "",
      clans_list: [
        {
          "id": 1,
          "name": "Test Clan",
          "owner_username": "niumandzi",
          "silver": 100000,
          "number_of_members": 1,
          "type": ""
        },
      ]
    }
  },
  mounted() {
    this.GetClansList();
  },
  methods: {
    CreateClan() {
      this.create_clan_page_visible = true;
    },

    CreateClanCancel(){
      this.create_clan_page_visible = false;
    },

    CreateClanSuccess(clanID){
      let page_type = 2;
      this.$emit('ChangePage', {page_type, clanID});
    },

    Search() {},

    VisitClanPage(ClanID){
      this.$emit('ChangePage', {ClanID: ClanID});
    },

    async RefreshData(){
      this.GetClansList().then(() => {
        this.refreshed = true;
      });
    },

    //api

    async GetClansList(){
      let data = {
        "page": 0,
        "list_amount": 10,
      };
      await this.GetClansListRequest(data);
    },

    async GetClansListRequest(data){
      this.initData = window.Telegram.WebApp.initData;
      let url = "https://cryptocowboy.ru/api/v1/clans";
      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      await axios
        .post(url, data)
        .then((response) => {
          this.clans_list = response.data;
        })
        .catch((error) => {console.log("on " + url, error);});
    },

    async GetClansSearch(){
      this.initData = window.Telegram.WebApp.initData;
      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      let url = `https://cryptocowboy.ru/api/v1/clans/search/${this.searchNameClan}`

      const data = {
        name: this.searchNameClan
      };

      await axios
          .get(url, {params: data})
          .then((response) => {
            this.clans_list = response.data;
          })
          .catch((error) => {console.log("on " + url, error);});
    },
  }
}
</script>