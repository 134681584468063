<template>
  <div class="wood_cont high_wood">
    <img class="wood" src="../assets/Upgrade_assets/upgrade.png"/>
  </div>
  <div class="new_z_index shadow_end" v-if="visible" @click="Cancel()">
      <div class="frame">
        <img src="../assets/MoneyImages/want_to_buy.png" class="want_to_buy"/>
        <div class="coins_"><img :src="options[selected_number].src"/></div>
        <span>{{ options[selected_number].title }}/{{ options[selected_number].price }}{{ options[selected_number].currency }}</span>
        <div class="continue_button" @click="Confirm()">
          <img src="../assets/svgs/buy.svg"/>
        </div>
      </div>
    </div>
  <div class="GoldBuy high_gold">
    <img src="../assets/MoneyImages/bank.svg" class="bank">
    <div class="money_container">
      <div class="money_frame" v-for="elem in options" v-bind:key="elem" @click="Select(elem.number)">
        <img class="img_gold-bg" src="../assets/frame_for_gold.svg"/>
        <div class="coins_"><img :src="elem.src"/></div>
        <span>{{ elem.title }}/{{ elem.price }}{{ elem.currency }}</span>
      </div>
    </div>
  </div>
</template>

<style>
@import "../styles/DonatePageStyles.css";
</style>

<script>
import axios from "axios";

export default {
  data() {
    return {
      visible: false,
      selected_number: 0,

      options: [
        {
          src: "./MoneyImagesPublic/gold 1.png",
          number: 0,
          "id": 0,
          "title": "100",
          "description": "string",
          "price": 1,
          "currency": "TON",
          "payload": "string"
        },
        {
          src: "./MoneyImagesPublic/gold 2.png",
          number: 1,
          "id": 1,
          "title": "200",
          "description": "string",
          "price": 2,
          "currency": "TON",
          "payload": "string"
        },
        {
          src: "./MoneyImagesPublic/gold 3.png",
          number: 2,
          "id": 2,
          "title": "500",
          "description": "string",
          "price": 3,
          "currency": "TON",
          "payload": "string"
        },
        {
          src: "./MoneyImagesPublic/gold 4.png",
          number: 3,
          "id": 3,
          "title": "1000",
          "description": "string",
          "price": 5,
          "currency": "TON",
          "payload": "string"
        },
        {
          src: "./MoneyImagesPublic/gold 5.png",
          number: 4,
          "id": 4,
          "title": "2000",
          "description": "string",
          "price": 6,
          "currency": "TON",
          "payload": "string"
        },
        {
          src: "./MoneyImagesPublic/gold 6.png",
          number: 5,
          "id": 5,
          "title": "5000",
          "description": "string",
          "price": 7,
          "currency": "TON",
          "payload": "string"
        },
      ],

      choosen_option: 1,
    };
  },
  mounted() {
    this.GetProductsrequest();
  },
  methods: {
    Select(number) {
      this.visible = true;
      this.selected_number = number;
    },

    Cancel() {
      this.visible = false;
    },

    Confirm() {
      this.PostProductInvoice(this.options[this.selected_number].id);
    },

    // api

    async PostProductInvoice(id) {
      this.initData = window.Telegram.WebApp.initData;
      let url = "https://cryptocowboy.ru/api/v1/payloads/products/" + id + "/invoice";

      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      await axios
        .post(url)
        .then((response) => {

          window.Telegram.WebApp.openInvoice(response.data.invoice_link, (status) => {
            if (status == "paid") {
              console.log("Successful payment");
              this.$emit('Money_upd');
            } else {
              console.log("Payment failed", status);
            }
          });

        })
        .catch((error) => {
          console.log("on " + url, error);
        });
    },

    async GetProductsrequest() {
      this.initData = window.Telegram.WebApp.initData;
      let url = "https://cryptocowboy.ru/api/v1/payloads/products";

      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      await axios
        .get(url)
        .then((response) => {
          this.options = response.data;
          for (let i = 0; i < response.data.length; i++) {
            this.options[i].src = `./MoneyImagesPublic/gold ${i % 6 + 1}.png`;
            this.options[i].number = i;
          }
        })
        .catch((error) => {
          console.log("on " + url, error);
        });
    }
  }
};
</script>
