<template>
  <div class="centring shadow_end leave">
    <div class="frame">
      <div class="skull img_wait enter_name">
        <img src="../assets/clans/enter_clan_name.svg"/>
      </div>
      <div class="cross_but"><img src = "../assets/clans/button_cross.svg" @click="Cancel()"></div>
      <div class="money_box">
        <div class="m_rec">
          <input v-model="tmp_clan_name" placeholder="ENTER CLAN"/>
          <div class="s_m_rec"></div>
        </div>
      </div>

      <div class="continue_button" @click="ChangeName()"></div>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
import axios from "axios";

export default {
  props: ["ClanID"],
  data() {
    return {
      tmp_clan_name: ''
    }
  },
  methods: {
    ChangeName() {
      this.RenameRequest().then(() => {
        this.$emit('SetClanName', this.tmp_clan_name);
        this.$emit('CancelEditingClan');
        this.tmp_clan_name = '';
      });
    },

    Cancel(){
      this.$emit('CancelEditingClan');
    },

    async RenameRequest(){
      this.initData = window.Telegram.WebApp.initData;
      let url = "https://cryptocowboy.ru/api/v1/clans/update";

      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;
      console.log("AAAAAAAAAAAAAA");
      console.log(this.ClanID);
      await axios
        .post(url, {
           clan_id: this.ClanID,
           name: this.tmp_clan_name,
        })
        .then(() => {
          this.clan.type = 'member';
        })
        .catch((error) => {
          console.log("on " + url, error);
        });
    },
  }
}
</script>