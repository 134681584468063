<template>
  <div class="wood_cont">
    <img class="wood" src="../assets/Upgrade_assets/upgrade.png" />
  </div>
  <!--Clans -->
  <div class="clans" v-if="page">
    <div class="wanted_box"><img src="../assets/MoneyImages/wanted.png" class = "adapt_size"/></div>
    <div class="_frame_clan">
      <div class="block" v-for="clan in clans_list" v-bind:key="clan">
        <img style="width: 65px; height: 65px; object-fit: cover" src="../assets/MoneyImages/clan_pic.svg" />

        <div class="block_text">
          <!-- нужно сделать ограничение на длину имени -->
          <div class = "upper" style="display: flex; justify-content: space-between;">
            <span>{{ clan.name }}</span>
            <span style="font-size: 14px; max-width: 80px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;">{{ clan.owner_username }}</span>
          </div>

          <span style="font-size: 13px;">{{ clan.silver }} silver</span>
          <span style="font-size: 13px;">{{ clan.clan_members }} members</span>
        </div>
      </div>
    </div>
  </div>

  <!--User status-->
  <div class="clans" v-if="!page">
    <div class="wanted_box"><img src="../assets/MoneyImages/wanted.png" class = "adapt_size"/></div>
    <div class="_frame_clan">
      <div class="block" v-for="user in users_list" v-bind:key="user">
        <img class="userImg" v-if="user.photo_url === ''" src="../assets/MoneyImages/face.svg" />
        <img class="userImg" v-else :src="user.photo_url" />
        <div class="block_text">
          <span class = "upper">{{ user.username }}</span>

          <div class = "player_info">
            <span>{{ user.silver }} silver</span>
            <div>{{ user.horse_lvl }} LVL</div>
          </div>
        </div>
    </div>
  </div>
  </div>
  <div class="z-button" @click="Switch_view()" >
    <img src = "../assets/TopBar_assets/globus.svg" v-if="!page">
    <img src ="../assets/TopBar_assets/person_btn.svg" v-if="page">
  </div>
</template>

<style>
@import "../styles/StatisticsViewStyles.css";
</style>

<script>
import axios from "axios";

export default {
  name: "StatisticsView",
  data() {
    return {
      //loaders
      img_loading: 0,
      imgs_loaded: false,
      api_responsed: false,

      //functional
      page: true,

      //data
      clans_list: [
        {
          "id": 18,
          "name": "TestClan",
          "owner_username": "niumadzi",
          "silver": 1000000,
          "clan_members": 1
        },
      ],
      users_list:
      [
        {
          "id": 1047613776,
          "username": "niumandzi",
          "photo_url": "https://t.me/i/userpic/320/rddYGw2PW2DcgDSIl0xDPo7xwbFbhkg3MGI5DEv2wXg.svg",
          "silver": 1000000,
          "horse_lvl": 10
        },
        {
          "id": 5776483288,
          "username": "black_c4t",
          "photo_url": "https://t.me/i/userpic/320/kSoBTlfTcJ3f6ng0iQdD5bFUaXUR9FqU-nK0U0i9WLxZKvIVqPKfNQSALy_apzEz.svg",
          "silver": 100000,
          "horse_lvl": 5
        },
        {
          "id": 3,
          "username": "3",
          "photo_url": "",
          "silver": 0,
          "horse_lvl": 1
        },
      ],
    };
  },
  async mounted() {
    // this.$emit("component_loading", { name: "ClansView" });
    let images = document.querySelectorAll("img");
    this.img_loading = images.length;
    for (let item of images) item.onload = this.img_loaded();

    await this.getData("stats/clans");
  },
  computed: {
    page_loaded() {
      return this.imgs_loaded;
    },
  },
  watch: {
    page_loaded() {
      this.$emit("component_loading", { name: "StatisticsView" });
    },
  },
  methods: {
    img_loaded() {
      this.img_loading--;
      if (this.img_loading === 0) this.imgs_loaded = true;
    },

    Switch_view(){
      this.page = !this.page;
      this.Refresh();
    },

    Refresh() {
      if (this.page) {
        this.getData("stats/clans");
      } else {
        this.getData("stats/users");
      }
    },

    //api
    async getData(link) {
      this.initData = window.Telegram.WebApp.initData;

      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      await axios
        .get("https://cryptocowboy.ru/api/v1/" + link)
        .then((response) => {
          if (link === "stats/clans")
            this.clans_list = response.data;
          else if (link === "stats/users")
            this.users_list = response.data;
        })
        .catch((error) => {
          console.log(`on get ${link}: `, error);
        });
    },
  },
};
</script>
