<template>
  <LeaveClan @CancelLeaving="CancelLeaving" @LeaveClanConfirmed="LeaveClanConfirmed" :user_type="clan.type" :ClanId="clan.id" v-if="leave_clan_visible"></LeaveClan>

  <div class="wood_cont create_clan">
    <img class="wood" src="../assets/Upgrade_assets/upgrade.png"/>
  </div>

  <div class="clans clans-page">

    <ChangeClanName :clanID="this.LocalClanId" @CancelEditingClan="CancelEditingClan" @SetClanName="SetClanName" v-if="change_clan_name_visible"></ChangeClanName>

    <!--clan info-->
    <div class="make_clan">
     
      <img src="../assets/clans/clans_frame.svg" class="make-clan-frame"/>
     
      <div class="clans-page-box">
        
        <span class="weight">{{ clan.name }}</span>
        <span class="weight">{{ clan.silver }} silver</span>
        <span class="weight">{{ clan.number_of_members }} members</span>
        <span class="weight">{{ clan.owner.username }}</span>
        <span class="weight">{{ clan.owner.silver }} silver</span>
        <span class="weight">{{ clan.owner.horse_lvl }} lvl</span>
        <img class="back_btn" src = "../assets/TopBar_assets/back.svg" @click="ReturnToClanList()">
        <!--buttons-->
        <div class="btn_section">
          <v-spacer></v-spacer>
          <div class="mini_button" v-if="clan.type === 'user' && user_status==='not_in_clan'" @click="JoinClan()"><img src="../assets/clans/btn_plus.svg"/></div>
          <div class="mini_button" v-if="clan.type === 'owner'" @click="EditClan()"><img src="../assets/clans/change.svg"/></div>
          <div class="mini_button" v-if="clan.type === 'member' || clan.type === 'owner'" @click="LeaveClan()"><img src="../assets/clans/cross.svg"/></div>
          <v-spacer></v-spacer>
        </div>
      </div>
    </div>

    <!--members list-->
    <div class="_frame white scroll-add">

      <div class="block" v-for="member in clan_members" v-bind:key="member">
        <img src="../assets/MoneyImages/person1.svg" v-if="member.photo_url === ''"/>
        <img :src="member.photo_url" v-if="member.photo_url !== ''"/>
        <div class="block_text">
          <span class = "upper">{{ member.username }}</span>
          <div class = "player_info">
            <span>{{ member.silver }} SILVER</span>
            <span>{{ member.horse_lvl }} LVL</span>
          </div>
        </div>
      
        </div>

    </div>
  </div>
</template>

<style>
.back_btn {
  position: absolute;
  top:25px;
  left:-10px;
  width: 35px;
  z-index: 19;
}
</style>

<script>
import axios from "axios";
import LeaveClan from "@/components/ClansView__LeaveClan.vue";
import ChangeClanName from "@/components/ClansView__ChangeClanName.vue";

export default {
  components: {
    LeaveClan,
    ChangeClanName,
  },
  props: ["clanID", "user_status"],
  data() {
    return {
      change_clan_name_visible: false,
      leave_clan_visible: false,

      refreshed: 0,
      LocalClanId: 0,

      clan: {
        "id": 0,
        "name": "Test Clan",
        "silver": 100000,
        "number_of_members": 3,
        "link": "",
        "is_open": false,
        "owner": {
          "username": "niumandzi",
          "silver": 10000,
          "horse_lvl": 12
        },
        "type": "member"
      },

      clan_members: [
        {
          "id": 1,
          "username": "user 1",
          "photo_url": "",
          "silver": 100,
          "horse_lvl": 5
        },
        {
          "id": 2,
          "username": "user 2",
          "photo_url": "",
          "silver": 200,
          "horse_lvl": 10
        },
        {
          "id": 3,
          "username": "user 3",
          "photo_url": "",
          "silver": 300,
          "horse_lvl": 15
        },
      ],
    };
  },
  watch: {
    refreshed() {
      if (this.refreshed === 2){
        this.refreshed = 0;
      }
    },
  },
  methods: {
    JoinClan(){
      this.JoinClanRequest();
    },

    EditClan(){
      this.change_clan_name_visible = true;
    },

    CancelEditingClan(){
      this.change_clan_name_visible = false;
    },

    SetClanName(clan_name){
      this.clan.name = clan_name;
    },

    LeaveClan(){
      this.leave_clan_visible = true;
    },

    LeaveClanConfirmed(){
      console.log("LeaveClan");
      this.clan.type = 'user';
      this.$emit('SetUserStatus', 'not_in_clan');
      this.$emit('ChangePage', {ClanID: 0});
    },

    CancelLeaving(){
      console.log('Cancelled');
      this.leave_clan_visible = false;
    },

    ReturnToClanList(){
      this.$emit('ChangePage', {ClanID: 0});
    },

    async RefreshData(){
      this.GetClanInfoRequest(this.clanID).then(() => this.refreshed += 1);

      let data = {
        "clan_id": this.clanID,
        "page": 0,
        "list_amount": 5
      };
      this.GetClanMembersRequest(data).then(() => this.refreshed += 1);
    },

    //api

    async JoinClanRequest(){
      this.initData = window.Telegram.WebApp.initData;
      let url = "https://cryptocowboy.ru/api/v1/clans/" + this.clanID + "/join";

      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      await axios
        .post(url)
        .then(() => {
          this.clan.type = 'member';
          this.$emit('SetUserStatus', 'in _clan');
        })
        .catch((error) => {console.log("on " + url, error);});
    },

    async GetClanInfoRequest(clanID){
      this.initData = window.Telegram.WebApp.initData;
      let url = "https://cryptocowboy.ru/api/v1/clans/info/" + clanID;
      this.LocalClanId = clanID;

      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      await axios
        .get(url)
        .then((response) => {
          this.clan = response.data;
        })
        .catch((error) => {console.log("on " + url, error);});
    },

    async GetClanMembersRequest(data){
      this.initData = window.Telegram.WebApp.initData;
      let url = "https://cryptocowboy.ru/api/v1/clans/members";

      axios.defaults.headers.common["X-User-Data"] = `${this.initData}`;

      await axios
        .post(url, data)
        .then((response) => {
          this.clan_members = response.data;
        })
        .catch((error) => {console.log("on " + url, error);});
    },
  },
}
</script>